import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Collapse from 'components/animations/Collapse'
import Modal from 'components/common/Modal'
import Button from 'components/common/buttons/Button'
import InfoSolidIcon from 'components/common/icons/InfoSolidIcon'

type VWIProps = {
  isVisible: boolean
}

const VWInfo: FC<VWIProps> = ({ isVisible }) => {
  const { t: translated } = useTranslation()
  const [isVWModalVisible, setIsVWModalVisible] = useState(false)

  return (
    <>
      <Collapse isExpanded={isVisible}>
        <button
          className='mb-4 py-4 w-full flex justify-center items-center gap-2'
          onClick={() => setIsVWModalVisible(true)}
        >
          <InfoSolidIcon className={'h-6 w-6 flex-none fill-current self-start  text-blue-400'} />
          <p>{translated('Virtual Warehouse Conditions')}</p>
        </button>
      </Collapse>

      <Modal
        contentClassName='transition-all'
        size='lg'
        allowEasyClose={true}
        onClose={() => setIsVWModalVisible(false)}
        isOpen={isVWModalVisible}
        title={translated('Virtual Warehouse Conditions')}
      >
        <ol className='space-y-4'>
          {[
            translated('The products will be added to your Virtual Warehouse inventory.'),
            translated(
              'You agree to sell the products to customers within 7 calendar days, but no later than the 28th day of the current month.'
            ),
            translated(
              "Initially you won't be charged any fees. After 7 days, any unsold products will be sent to you and you will be invoiced. That's why you have to select a delivery address and a payment method."
            )
          ].map((text, index) => (
            <li className='grid grid-cols-[min-content_auto] gap-2 md:gap-4' key={index}>
              <span className='inline-block h-7 w-7 leading-7 rounded-full bg-secondary text-primary text-center'>
                {index + 1}
              </span>
              <span className='inline-block text-justify'>{text}</span>
            </li>
          ))}
        </ol>

        <div className='mt-4 flex items-center justify-center'>
          <Button onClick={() => setIsVWModalVisible(false)} variant='primary'>
            <span>{translated('Got it')}</span>
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default VWInfo

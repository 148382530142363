import { isValidTheme, Theme, ThemeManager, useTheme } from '@pmi.web/react-theme'
import { FC, ReactNode, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

type TPProps = {
  readonly children?: ReactNode
}

const themeColor = {
  fitline: '#cd0039',
  pm: '#004b8d'
}
const getIconDirNameBasedOnTheme = (theme: Theme) => (theme === Theme.FitLine ? 'fl' : 'pm')

export const ThemeProvider: FC<TPProps> = ({ children }) => {
  const { theme, setTheme } = useTheme()

  /**
   * The theme query param always overrides the app-state value
   */
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const themeSearchParam = searchParams.get('theme')
    if (themeSearchParam && isValidTheme(themeSearchParam)) {
      setTheme(themeSearchParam)
    }
  }, [searchParams, setTheme])

  useEffect(() => {
    // update "theme-color" <meta> tag
    const themeMetaTag = document.querySelector('meta[name="theme-color"]')
    if (themeMetaTag) themeMetaTag.setAttribute('content', themeColor[theme])

    // Update "description" <meta> tag
    const descriptionMetaElement = document.querySelector('meta[name="description"]')
    if (descriptionMetaElement)
      descriptionMetaElement.setAttribute(
        'content',
        theme === Theme.FitLine ? 'FitLine Shop' : 'PM Shop'
      )

    // update favicon.ico
    const faviconLinkElement = document.querySelector('link[rel="icon"]')
    if (faviconLinkElement)
      faviconLinkElement.setAttribute('href', `/${getIconDirNameBasedOnTheme(theme)}/favicon.ico`)

    // update Apple touch icon
    const appleIconLinkElement = document.querySelector('link[rel="apple-touch-icon"]')
    if (appleIconLinkElement)
      appleIconLinkElement.setAttribute(
        'href',
        `/${getIconDirNameBasedOnTheme(theme)}/logo-192.png`
      )

    // update manifest.json
    const manifestLinkElement = document.querySelector('link[rel="manifest"]')
    if (manifestLinkElement)
      manifestLinkElement.setAttribute('href', `/${getIconDirNameBasedOnTheme(theme)}/logo-192.png`)

    // set <title> tag
    const titleElement = document.querySelector('head title')
    if (titleElement)
      titleElement.textContent = theme === Theme.FitLine ? 'FitLine Shop' : 'PM Shop'
  }, [theme])

  return (
    <>
      <ThemeManager defaultTheme={Theme.FitLine} />
      {children}
    </>
  )
}

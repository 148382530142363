import PMPoints from 'components/common/PMPoints'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

import type { ICartItem } from 'typings/cartTypes'
import { formatPrice, getUnitPrice } from 'utils/priceUtils'

type VWCartItemProps = ICartItem & {
  customClasses?: string
  amountInUnits?: number
  unitSymbol?: string
  showPoints?: boolean
}

const VWCartItem: FC<VWCartItemProps> = ({
  quantity,
  name,
  image,
  price,
  points,
  currency,
  customClasses,
  amountInUnits,
  unitSymbol,
  showPoints = false,
  addOnItems
}) => {
  const { localeCode: localeCodePathParam } = useParams()

  const priceDisplayed = formatPrice(price, currency, localeCodePathParam ?? '')
  const unitPriceDisplayed = getUnitPrice({
    price,
    amountInUnits,
    currencyCode: currency,
    localeCode: localeCodePathParam,
    unitSymbol
  })

  if (currency.length <= 0) return null

  return (
    <div
      className={
        'max-w-lg md:max-w-xl mx-auto py-4 md:py-6 px-0 space-y-3 md:space-y-5' +
        (customClasses ? ` ${customClasses}` : '')
      }
    >
      <div className='flex gap-x-2 md:gap-x-6'>
        <div className='relative'>
          <div
            className={
              'relative h-[7.5rem] w-[7.5rem] flex-shrink-0 overflow-hidden' +
              ' rounded-md bg-white'
            }
          >
            <img
              src={image}
              alt={name}
              className='h-full w-full object-cover object-center text-xs'
            />
          </div>
        </div>

        <div className='py-0.5 flex flex-1 flex-col justify-between gap-y-1'>
          <p className='text-sm md:text-base font-medium text-gray-900'>{name}</p>

          <div className='flex justify-between items-start'>
            <PMPoints value={points} forceShow={showPoints} />
            <div className='flex flex-col items-end gap-y-1'>
              <p className='text-base font-bold text-right'>{`${quantity} x ${priceDisplayed}`}</p>

              {unitPriceDisplayed && (
                <p className='text-xs font-light text-gray-500'>{`(${unitPriceDisplayed})`}</p>
              )}
            </div>
          </div>

          {addOnItems?.map((item, index) =>
            !!item?.details?.attributes?.name?.value && item?.details?.displayPrice ? (
              <p className='flex justify-between text-xs' key={index}>
                <span className=''>{item.details.attributes.name.value}</span>
                <span className='font-bold'>{`${item.quantity} x ${formatPrice(
                  item.details.displayPrice,
                  currency,
                  localeCodePathParam ?? ''
                )}`}</span>
              </p>
            ) : null
          )}
        </div>
      </div>
    </div>
  )
}

export default VWCartItem

import { Banner, BannerCarousel } from '@pmi.web/react-common'
import type { IPromoBanner } from 'typings/shopApi'

const PromoBanner = ({ data }: { data: IPromoBanner[] }) => {
  return (
    <BannerCarousel autoplay>
      {data?.map(banner => (
        <Banner
          key={banner.id}
          title={banner.title}
          description={banner.details}
          countdownTo={banner?.countdown ? new Date(banner.countdown) : undefined}
          ctaLabel={banner?.cta?.label}
          ctaUrl={banner?.cta?.url}
        />
      ))}
    </BannerCarousel>
  )
}

export default PromoBanner

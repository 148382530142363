import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import AnimatedNumber from 'components/effects/AnimatedNumber'
import { useAuthContext } from 'context/auth'

type PMPointsProps = {
  value: number | undefined
  animated?: boolean
  customClasses?: string
  forceShow?: boolean
}

const PMPoints: FC<PMPointsProps> = ({
  value,
  animated = false,
  customClasses,
  forceShow = false
}) => {
  const { t: translated } = useTranslation()
  const { isCustomer } = useAuthContext()
  const formattedValue = Number.isInteger(value) ? value : value?.toFixed(2)

  const shouldRenderPoints = forceShow || !isCustomer

  if (formattedValue === undefined || !shouldRenderPoints) {
    return <span />
  }

  return (
    <span
      className={
        ' flex items-center gap-0.5' +
        (customClasses ? ` ${customClasses}` : ' text-base leading-3')
      }
    >
      {animated ? (
        <AnimatedNumber value={formattedValue} className='' />
      ) : (
        <span>{formattedValue}</span>
      )}
      <span className='text-silver'>Ⓟ</span>
      <span className='sr-only'>{translated('Points')}</span>
    </span>
  )
}

export default PMPoints

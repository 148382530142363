import { FC, ReactNode } from 'react'
import { Link, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import { useAppContext } from 'context/app'

import {
  secondHeaderButtonVariants,
  containerVariants,
  logoVariants,
  menuVariants,
  additionalContentVariants
} from './PageHeader.motion'

import PMLogo from './PMLogo'
import FitLineLogo from './FitLineLogo'
import UserMenu from './UserMenu'
import { Theme, useTheme } from '@pmi.web/react-theme'

type PHProps = {
  readonly children?: ReactNode
  readonly customClasses?: string
}

export const PageHeader: FC<PHProps> = ({ children, customClasses }) => {
  const { localeCode: localeCodePathParam } = useParams()
  const { shopId } = useAppContext()
  const { theme } = useTheme()

  return (
    <motion.header
      className={'z-20 sticky top-0 left-0 w-full p-0 ' + customClasses}
      variants={containerVariants}
    >
      <section id='top-notification' />

      <div
        className={
          'z-20 relative' +
          ' h-[56px] sm:h-[72px]' +
          ' flex justify-between items-center' +
          ' px-5' +
          ' bg-white shadow-lg'
        }
      >
        <motion.div variants={logoVariants}>
          <Link
            to={{ pathname: `/${localeCodePathParam}/shop/${shopId}`, search: location.search }}
            className='block'
            // when viewing baskets that do not have a shop ID configured, the logo is not a link
            style={{ pointerEvents: shopId ? 'all' : 'none' }}
          >
            {theme === Theme.FitLine ? <FitLineLogo className='relative' /> : <PMLogo />}
          </Link>
        </motion.div>

        <div className='flex flex-row-reverse gap-4 items-center'>
          <motion.div variants={menuVariants} id='header-button-1'>
            <UserMenu />
          </motion.div>

          <motion.div
            variants={secondHeaderButtonVariants}
            id='header-additional-buttons'
            className='flex flex-row-reverse items-center justify-start gap-4'
          />
        </div>
      </div>

      <motion.div className={'relative w-full'} variants={additionalContentVariants}>
        <div
          className={
            'z-0 block absolute -top-12 left-0 right-0 -bottom-2 backdrop-blur bg-secondary-lighter' +
            ' mask-bottom'
          }
        />
        <div id='header-additional-content' className='relative z-10 w-full'>
          {children}
        </div>
      </motion.div>
    </motion.header>
  )
}

export default PageHeader

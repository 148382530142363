import { FC } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { motion } from 'framer-motion'

import { BsCalendar2Week } from 'react-icons/bs'
import { FaBriefcase } from 'react-icons/fa'
import { LiaClipboardListSolid } from 'react-icons/lia'
import { MdNumbers } from 'react-icons/md'
import { RiExternalLinkLine } from 'react-icons/ri'
import { HiOutlineMagnifyingGlassPlus } from 'react-icons/hi2'

import type { IOrder } from 'typings/orderTypes'
import type { IApiError, IGenericServerError } from 'typings/errorTypes'
import config from 'config/index'

import { fetchOrderById } from 'services/orders'
import { formatPrice } from 'utils/priceUtils'
import { formatDate } from 'utils/dateUtils'
import useModal from 'hooks/useModal'
import { useAuthContext } from 'context/auth'

import { containerVariants, mainInfoVariants } from './OrderItemPage.motion'

import ScreenCover from 'components/common/ScreenCover'
import SpinnerPM from 'components/common/SpinnerPM'
import CloudDownloadIcon from 'components/common/icons/CloudDownloadIcon'
import MessageBox from 'components/common/MessageBox'
import Modal from 'components/common/Modal'
import ShopBreadcrumbs from 'components/shop/ShopBreadcrumbs'

const OrderItemPage: FC = () => {
  const { orderId, localeCode } = useParams()
  const { t: translated } = useTranslation()
  const { accessToken, isCustomer } = useAuthContext()

  const { isModalOpen, openModal, closeModal } = useModal()

  const {
    data: orderData,
    error: orderQueryError,
    isLoading: isOrderDataLoading,
    isError: isOrderQueryError
  } = useQuery<IOrder, IApiError | IGenericServerError>({
    enabled: !!orderId && isCustomer,
    queryKey: ['order', orderId],
    queryFn: () =>
      fetchOrderById({
        orderId: orderId ?? '',
        accessToken: accessToken ?? ''
      }) as Promise<IOrder>,
    staleTime: 1000 * 60 * 60,
    onError: basketContentError => {
      console.error(basketContentError)
    }
  })

  if (!isCustomer) {
    window.location.assign(`${config.officeAppUrl}/account/orders/${orderId}`)
    return (
      <ScreenCover>
        <SpinnerPM>
          <FaBriefcase size={25} />
        </SpinnerPM>
      </ScreenCover>
    )
  }

  if (isOrderDataLoading)
    return (
      <ScreenCover>
        <SpinnerPM>
          <CloudDownloadIcon />
        </SpinnerPM>
      </ScreenCover>
    )

  if (isOrderQueryError) {
    const errorMessage =
      'status' in orderQueryError && 'title' in orderQueryError
        ? `${orderQueryError?.status} ${orderQueryError?.title}`
        : `${orderQueryError?.statusCode} ${orderQueryError?.message}`
    return (
      <ScreenCover>
        <MessageBox type='error' text={errorMessage} showIcon={true} />
      </ScreenCover>
    )
  }

  if (!orderData) return <Navigate to='..' replace={true} />

  const orderReferenceNumber = orderData.reference
  const orderPickupNumber = orderData.delivery.pickupNumber
  const orderCreationDate = new Date(orderData.createdOn)
  const qrCodeUri = orderData.qrCodeUri
  const currencyCode = orderData.totals.currencyCode
  const totalOrderAmount = orderData.totals.roundedTotalGross
  const totalOrderTaxes = orderData.totals.totalTaxAmount
  const totalOrderPoints = orderData.totals.points
  const productTaxes = orderData.totals.itemsTaxAmount
  const taxGroups = orderData.totals.taxGroups
  const deliveryProvider = orderData.delivery.selected.localizedLabels[0].name
  const deliveryType = orderData.delivery.selected.type
  const deliveryCost = orderData.delivery.selected.cost.displayPrice
  const deliveryAddress = orderData.delivery.address
  const itemList = orderData.items
  const additionalCharges = orderData.additionalCharges ?? []
  const terms = orderData.terms

  return (
    <>
      <ShopBreadcrumbs
        routeSymbols={[
          <Link
            key='breadcrumb-order-list'
            className='inline-block max-w-prose overflow-hidden whitespace-nowrap text-ellipsis text-primary'
            to={{ pathname: '..', search: location.search }}
          >
            {translated('My orders')}
          </Link>,
          <p
            key='breadcrumb-order-details'
            className='inline-block max-w-prose overflow-hidden whitespace-nowrap text-ellipsis'
          >
            {translated('Order details')}
          </p>
        ]}
      />

      <motion.div
        className={
          'w-full max-w-3xl mx-auto' +
          ' mb-4 md:mb-6 p-0 md:p-4' +
          ' bg-white' +
          ' border border-secondary rounded-md' +
          ' shadow-md'
        }
        variants={containerVariants}
      >
        <motion.div
          className={'py-4 px-3 md:px-4 space-y-4' + ' text-sm md:text-base text-gray-500'}
          variants={mainInfoVariants}
        >
          <div className='grid grid-cols-[1fr_auto]'>
            <div className='flex flex-col justify-start space-y-4'>
              <div className='grid grid-cols-[min-content_1fr] grid-rows-2 gap-x-2 items-center'>
                <div
                  className={
                    'h-10 w-10' +
                    ' row-span-2 col-span-1' +
                    ' grid place-content-center' +
                    ' rounded-full bg-gray-100'
                  }
                >
                  <MdNumbers size={22} />
                </div>

                <p className='font-light text-gray-400 text-xs md:text-sm'>
                  {translated('Reference number')}
                </p>
                <p className='font-regular'>{orderReferenceNumber}</p>
              </div>

              <div className='grid grid-cols-[min-content_1fr] grid-rows-2 gap-x-2 items-center'>
                <div
                  className={
                    'h-10 w-10' +
                    ' row-span-2 col-span-1' +
                    ' grid place-content-center' +
                    ' rounded-full bg-gray-100'
                  }
                >
                  <BsCalendar2Week size={18} />
                </div>

                <p className='font-light text-gray-400 text-xs md:text-sm'>{translated('Date')}</p>
                <p className='font-regular'>{formatDate(orderCreationDate, localeCode ?? '')}</p>
              </div>

              {orderPickupNumber && (
                <div className='grid grid-cols-[min-content_1fr] grid-rows-2 gap-x-2 items-center'>
                  <div
                    className={
                      'h-10 w-10' +
                      ' row-span-2 col-span-1' +
                      ' grid place-content-center' +
                      ' rounded-full bg-gray-100'
                    }
                  >
                    <LiaClipboardListSolid size={22} />
                  </div>

                  <p className='font-light text-gray-400 text-xs md:text-sm'>
                    {translated('Pick-up number')}
                  </p>
                  <p className='font-regular'>{orderPickupNumber}</p>
                </div>
              )}
            </div>

            {deliveryType === 'pickup' && (
              <div className='space-y-2'>
                {qrCodeUri && (
                  <button
                    className={'group relative h-24 w-24 flex items-center justify-center'}
                    onClick={() => {
                      if (qrCodeUri) openModal()
                    }}
                    aria-label={translated('Expand QR code')}
                  >
                    <img
                      src={qrCodeUri}
                      alt={translated('QR code for this order')}
                      className={
                        'z-10' + ' scale-95 transition-transform duration-300 group-hover:scale-105'
                      }
                    />

                    <div className='z-20 absolute inset-0 bg-secondary mix-blend-multiply' />

                    <div
                      className={
                        'h-8 w-8' +
                        ' absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 z-30' +
                        ' grid place-content-center' +
                        ' rounded-full bg-secondary opacity-100' +
                        ' scale-100 transition-all duration-300 group-hover:opacity-0 group-hover:scale-50'
                      }
                    >
                      <HiOutlineMagnifyingGlassPlus size={22} className=' text-primary' />
                    </div>
                  </button>
                )}
              </div>
            )}
          </div>

          <div className='h-px w-full bg-gradient-to-r from-transparent via-secondary-darker via-10%' />

          <div className='flex justify-around'>
            {totalOrderAmount > 0 && (
              <p className='flex flex-col text-center'>
                <span className='font-light'>{translated('Total')}</span>
                <span>{formatPrice(totalOrderAmount, currencyCode, localeCode ?? '')}</span>
              </p>
            )}

            {totalOrderTaxes > 0 && (
              <p className='flex flex-col text-center'>
                <span className='font-light'>{translated('Taxes')}</span>
                <span>{formatPrice(totalOrderTaxes, currencyCode, localeCode ?? '')}</span>
              </p>
            )}

            {!isCustomer && totalOrderPoints != null && (
              <p className='flex flex-col text-center'>
                <span className='font-light'>{translated('Points')}</span>
                <span>{totalOrderPoints}</span>
              </p>
            )}
          </div>

          <div className='h-px w-full bg-gradient-to-r from-transparent via-secondary-darker via-10%' />

          <table className='w-full border-separate border-spacing-1'>
            <caption className='font-bold'>{translated('Ordered items')}</caption>

            <thead>
              <tr>
                <th className='align-center font-normal'>{translated('Product name')}</th>
                <th className='align-center font-normal'>{translated('Amount')}</th>
                <th className='align-center text-right font-normal'>{translated('Price')}</th>
                {!isCustomer && (
                  <th className='align-center text-right font-normal hidden md:block'>
                    {translated('Points')}
                  </th>
                )}
              </tr>
            </thead>

            <tbody>
              {itemList.map(orderItem => (
                <tr className='odd:bg-secondary-lighter' key={orderItem.articleNumber}>
                  <td className='align-center text-left'>{orderItem.name}</td>
                  <td className='align-center text-center'>{orderItem.quantity}</td>
                  <td className='align-center text-right'>
                    {formatPrice(orderItem.cost.displayPrice, currencyCode, localeCode ?? '')}
                  </td>
                  {!isCustomer && (
                    <td className='align-center text-right hidden md:table-cell'>
                      {orderItem.points}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>

          <div className='h-px w-full bg-gradient-to-r from-transparent via-secondary-darker via-10%' />

          <div className='space-y-2'>
            <p className='text-center font-bold'>{translated('Delivery')}</p>

            <p className='text-left underline'>{deliveryProvider}</p>

            {deliveryType === 'shipment' && (
              <ul className={'mt-2 w-full space-y-1 px-0'}>
                {deliveryAddress?.addressLine1 && (
                  <li className='line space-x-1'>{deliveryAddress?.addressLine1}</li>
                )}
                {deliveryAddress?.addressLine2 && (
                  <li className='line space-x-1'>{deliveryAddress?.addressLine2}</li>
                )}
                {deliveryAddress?.streetPob && (
                  <li className='line space-x-1'>{deliveryAddress?.streetPob}</li>
                )}
                {deliveryAddress?.zipCode && (
                  <li className='line space-x-1'>{deliveryAddress?.zipCode}</li>
                )}
                {deliveryAddress?.city && (
                  <li className='line space-x-1'>{deliveryAddress?.city}</li>
                )}
                {/* {deliveryAddress?.county && (
                  <li className='line space-x-1'>
                    <span className='field-name'>{translated('County')}:</span>
                    <span className='field-value'>{deliveryAddress?.county}</span>
                  </li>
                )} */}
                {/* {deliveryAddress?.district && (
                  <li className='line space-x-1'>
                    <span className='field-name'>{translated('District')}:</span>
                    <span className='field-value'>{deliveryAddress?.district}</span>
                  </li>
                )} */}
                {/* {deliveryAddress?.federalState && (
                  <li className='line space-x-1'>
                    <span className='field-name'>{translated('State')}:</span>
                    <span className='field-value'>{deliveryAddress?.federalState}</span>
                  </li>
                )} */}
              </ul>
            )}

            {deliveryType === 'shipment' && deliveryCost > 0 && (
              <div className='flex justify-around'>
                {deliveryCost > 0 && (
                  <p className='flex flex-col'>
                    <span className='font-light'>{translated('Shipping cost')}</span>
                    <span>{formatPrice(deliveryCost, currencyCode, localeCode ?? '')}</span>
                  </p>
                )}
              </div>
            )}
          </div>

          {totalOrderTaxes > 0 && (
            <>
              <div className='h-px w-full bg-gradient-to-r from-transparent via-secondary-darker via-10%' />
              <div className='space-y-2'>
                <p className='text-center font-bold'>{translated('Taxes')}</p>

                <div className='flex justify-around'>
                  {productTaxes > 0 && (
                    <p className='flex flex-col text-center'>
                      <span className='font-light'>{translated('Product taxes')}</span>
                      <span>{formatPrice(productTaxes, currencyCode, localeCode ?? '')}</span>
                    </p>
                  )}

                  {totalOrderTaxes > 0 && (
                    <p className='flex flex-col text-center'>
                      <span className='font-light'>{translated('Total taxes')}</span>
                      <span>{formatPrice(totalOrderTaxes, currencyCode, localeCode ?? '')}</span>
                    </p>
                  )}
                </div>

                {taxGroups.length > 0 && (
                  <ul className='tax-groups'>
                    {taxGroups.map(({ taxRate, taxAmount }) => (
                      <li key={`tax-rate-${taxRate}`} className={'flex py-0 sm:py-0.5'}>
                        <span className='inline-block'>
                          {translated('Tax collected at a rate of {{taxRate}}%', { taxRate })}
                        </span>
                        <span className='inline-block ml-auto'>
                          {formatPrice(taxAmount, currencyCode, currencyCode ?? '')}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </>
          )}

          {additionalCharges.length > 0 && (
            <>
              <div className='h-px w-full bg-gradient-to-r from-transparent via-secondary-darker via-10%' />

              <div className='space-y-2'>
                <p className='text-center font-bold'>{translated('Additional charges')}</p>

                <ul className='additional-charges'>
                  {additionalCharges.map(({ cost, label, type }, index) => (
                    <li
                      key={`additional-charge-${index}-${type}`}
                      className={'flex py-0 sm:py-0.5'}
                    >
                      <span className='inline-block lowercase'>{label}</span>
                      <span className='inline-block ml-auto'>
                        {formatPrice(cost.displayPrice, cost.currencyCode, currencyCode ?? '')}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}

          {!!terms?.length && (
            <>
              <div className='h-px w-full bg-gradient-to-r from-transparent via-secondary-darker via-10%' />

              <div className='space-y-2'>
                <p className='text-center font-bold'>{translated('Terms and conditions')}</p>

                <ul className='terms-and-conditions'>
                  {terms?.map(({ localizedLabels }, index) => (
                    <li key={`condition-${index}`} className={'py-0 sm:py-0.5'}>
                      <a
                        href={localizedLabels[0].url}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={
                          'group flex justify-start items-center gap-1' +
                          ' hover:text-primary' +
                          ' focus:outline-none focus-visible:animate-pulsate' +
                          ' active:animate-pulsate'
                        }
                      >
                        <span>{localizedLabels[0].name}</span>
                        <RiExternalLinkLine
                          className={'text-base sm:text-lg' + ' group-hover:scale-110'}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </motion.div>

        <Modal isOpen={isModalOpen} onClose={closeModal} size='lg'>
          <img src={qrCodeUri} alt='qr code' className='w-full max-w-96' />
        </Modal>
      </motion.div>
    </>
  )
}

export default OrderItemPage

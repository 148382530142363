import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import type { IShopProduct } from 'typings/shopApi'
import type { IBasicBasket } from 'typings/checkoutApi'
import type { IApiError } from 'typings/errorTypes'

import { createBasket, fetchBasketList } from 'services/checkout'
import { useAppContext } from 'context/app'
import { useAuthContext } from 'context/auth'
import { useLocalizationContext } from 'context/localization'

import { showToast } from 'components/layout/ToastNotification'
import { Theme, useTheme } from '@pmi.web/react-theme'

export interface IShopContext {
  readonly productMap: Map<string, IShopProduct>
  readonly addProductsToMap: Dispatch<IShopProduct[]>
}

interface IECPProps {
  readonly children?: ReactNode
}

const ShopContext = createContext<IShopContext | null>(null)

export const ShopProvider: FC<IECPProps> = ({ children }) => {
  const queryClient = useQueryClient()
  const { accessToken } = useAuthContext()
  const { cartId, shopId, setCartId } = useAppContext()
  const { appLocale } = useLocalizationContext()
  const { theme } = useTheme()

  const [productMap, setProductMap] = useState<Map<string, IShopProduct>>(() => new Map())

  const createBasketMutation = useMutation<IBasicBasket, IApiError>({
    mutationFn: () =>
      createBasket(shopId ?? '', accessToken ?? '', {
        shopId: shopId ?? '',
        theme: theme ?? Theme.FitLine
      }),
    onSuccess: basketData => {
      queryClient.setQueryData(['basket', 'list', shopId], [basketData])
      setCartId(basketData.basketId)
    },
    onError: basketCreationError => {
      showToast({
        type: 'error',
        title: 'Failed to create basket'
      })
      console.error(basketCreationError)
    }
  })

  const { data: cartList } = useQuery<IBasicBasket[], IApiError>({
    enabled: !cartId && !!shopId && !!accessToken && !!appLocale,
    queryKey: ['basket', 'list', shopId],
    queryFn: () => fetchBasketList(shopId ?? '', accessToken ?? '', appLocale),
    staleTime: Infinity,
    onError: basketListError => {
      showToast({
        type: 'error',
        title: 'Failed to fetch basket list'
      })
      console.error(basketListError)
    }
  })

  useEffect(() => {
    if (!cartList) return

    if (cartList.length > 0) {
      const openCart = cartList.find(cart => cart.status !== 'paymentAuthorized')
      if (openCart) return setCartId(openCart.basketId)
    }

    if (!createBasketMutation.isLoading && !createBasketMutation.isError) {
      createBasketMutation.mutate()
    }
  }, [cartList, createBasketMutation, setCartId])

  const addProductsToMap = useCallback((products: IShopProduct[]) => {
    setProductMap(prevMap => {
      const newMap = new Map(prevMap)
      products.forEach(product => {
        if (!newMap.get(product.articleNumber)) newMap.set(product.articleNumber, product)
      })
      return newMap
    })
  }, [])

  const shopProviderValue = useMemo(
    () => ({ productMap, addProductsToMap }),
    [addProductsToMap, productMap]
  )

  return <ShopContext.Provider value={shopProviderValue}>{children}</ShopContext.Provider>
}

export const useShopContext = () => {
  const context = useContext(ShopContext)

  if (context === null) {
    throw new Error('useShopContext must be used within ShopProvider')
  }

  return context
}

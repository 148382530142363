import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import type { CustomErrorMessage } from 'typings/errorTypes'
import type { ICartItem } from 'typings/cartTypes'
import { formatPrice, getUnitPrice } from 'utils/priceUtils'

import { showToast } from 'components/layout/ToastNotification'
import TranslatedError from 'components/common/TranslatedError'
import PMPoints from 'components/common/PMPoints'
import CartAmountControls from 'components/cart/CartAmountControls'
import WarningSoidIcon from 'components/common/icons/WarningSolidIcon'

type CartItemProps = ICartItem & {
  inStock: boolean
  maxAmount: number
  incrementValue: () => void
  decrementValue: () => void
  setValue: (value: number) => void
  isBusy?: boolean
  validationError?: CustomErrorMessage
  clearError?: () => void
  customClasses?: string
  isDisabled?: boolean
  amountInUnits?: number
  unitSymbol?: string
}

const CartItem: FC<CartItemProps> = ({
  articleNumber,
  inStock,
  quantity,
  maxAmount,
  name,
  image,
  price,
  currency,
  points,
  validationError,
  clearError,
  customClasses,
  isDisabled,
  incrementValue,
  decrementValue,
  setValue,
  isBusy,
  amountInUnits,
  unitSymbol,
  addOnItems
}) => {
  const { localeCode: localeCodePathParam } = useParams()
  const { t: translated } = useTranslation()

  const isMaxAmountReached = (quantity ?? 0) >= maxAmount
  const priceDisplayed = formatPrice(price, currency, localeCodePathParam ?? '')
  const unitPriceDisplayed = getUnitPrice({
    price,
    amountInUnits,
    currencyCode: currency,
    localeCode: localeCodePathParam,
    unitSymbol
  })

  const handleIncrement = useCallback(() => {
    if (isDisabled) return

    if (!inStock)
      return showToast({
        type: 'warning',
        title: translated('This product is out of stock.')
      })

    if (isMaxAmountReached)
      return showToast({
        type: 'warning',
        title: translated('You can purchase up to {{maxNumber}} units of this product.', {
          maxNumber: maxAmount
        })
      })

    incrementValue()
    clearError?.()
  }, [clearError, inStock, incrementValue, isDisabled, isMaxAmountReached, maxAmount, translated])

  const handleDecrement = useCallback(() => {
    if (isDisabled) return
    decrementValue()
    clearError?.()
  }, [clearError, decrementValue, isDisabled])

  const handleSetValue = useCallback(
    (value: string) => {
      if (isDisabled) return
      setValue(parseInt(value))
      clearError?.()
    },
    [clearError, isDisabled, setValue]
  )

  if (currency.length <= 0) return null

  return (
    <div
      className={
        'max-w-lg md:max-w-xl mx-auto py-4 md:py-6 px-0 space-y-3 md:space-y-5' +
        (customClasses ? ` ${customClasses}` : '')
      }
    >
      <div className='flex gap-x-2 md:gap-x-6'>
        <div className='relative'>
          <div
            className={
              'relative h-[7.5rem] w-[7.5rem] flex-shrink-0 overflow-hidden' +
              ' rounded-md bg-white'
            }
          >
            <img
              src={image}
              alt={name}
              className='h-full w-full object-cover object-center text-xs'
            />
          </div>
        </div>

        <div className='py-0.5 flex flex-1 flex-col gap-y-1'>
          <p className='text-sm md:text-base font-medium text-gray-900'>{name}</p>

          <div className='flex justify-between items-start'>
            <PMPoints value={points} />

            <div className='flex flex-col'>
              <p className='text-base font-bold'>{priceDisplayed}</p>
              {unitPriceDisplayed && (
                <p className='text-xs font-light text-silver'>{`(${unitPriceDisplayed})`}</p>
              )}
            </div>
          </div>

          {addOnItems?.map((item, index) =>
            !!item?.details?.attributes?.name?.value && item?.details?.displayPrice ? (
              <p className='flex justify-between text-xs' key={index}>
                <span className=''>{item.details.attributes.name.value}</span>
                <span className='font-bold'>{`${item.quantity} x ${formatPrice(
                  item.details.displayPrice,
                  currency,
                  localeCodePathParam ?? ''
                )}`}</span>
              </p>
            ) : null
          )}

          <div className='mt-auto flex items-end justify-end grow-0'>
            <CartAmountControls
              id={`amount-control-${articleNumber}`}
              amount={String(quantity)}
              maxAmount={maxAmount}
              increment={handleIncrement}
              decrement={handleDecrement}
              set={value => handleSetValue(value)}
              isBusy={isBusy}
            />
          </div>
        </div>
      </div>

      {validationError && (
        <div
          className={
            'flex gap-2 justify-center items-center' +
            ' text-xs md:text-sm font-light tracking-wide text-red-400'
          }
        >
          <WarningSoidIcon className={'h-4 w-4 md:h-5 md:w-5 flex-none fill-current '} />

          <TranslatedError error={validationError} />
        </div>
      )}
    </div>
  )
}

export default CartItem

import { FC, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BsFillShieldLockFill } from 'react-icons/bs'

import type { User as UserAuthData } from 'oidc-client-ts'
import type { IUriLocation } from 'typings/urlTypes'

import { useAuthContext } from 'context/auth'

import ScreenCover from 'components/common/ScreenCover'
import SpinnerPM from 'components/common/SpinnerPM'

export const hasOidcStateInUrl = (location: IUriLocation): boolean => {
  const searchParams = new URLSearchParams(location.search)
  const hashParams = new URLSearchParams(location.hash.replace('#', '?'))

  return [
    'state',
    'session_state',
    'id_token',
    'access_token',
    'token_type',
    'expires_in',
    'code',
    'scope'
  ].some(property => searchParams.has(property) || hashParams.has(property))
}

export const AuthCallbackPage: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { signinRedirectCallback } = useAuthContext()

  const hasRenderedRef = useRef(false)

  useEffect(() => {
    ;(async () => {
      // use hasRenderedRef to prevent .signinRedirectCallback() from being called more than once
      if (hasOidcStateInUrl(location) && !hasRenderedRef.current) {
        hasRenderedRef.current = true

        try {
          const userData = await signinRedirectCallback()
          if (typeof userData?.state === 'string')
            return navigate(userData.state, {
              replace: true
            })
        } catch (error) {
          console.error('signinRedirectCallback failed')
        }
      }
    })()
  }, [location, navigate, signinRedirectCallback])

  return (
    <ScreenCover>
      <SpinnerPM>
        <BsFillShieldLockFill size={25} />
      </SpinnerPM>
    </ScreenCover>
  )
}

export default AuthCallbackPage

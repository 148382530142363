import ReactDOM from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import './index.css'

import ReactQueryContextProvider from 'context/query'
import { ThemeProvider } from 'context/theme'
import { AuthContextProvider } from 'context/auth'
import { PrivacyProvider } from 'context/privacy'
import { TelemetryProvider } from 'context/insights'
import { ShopProvider } from 'context/shop'
import { CartProvider } from 'context/cart'
import { VWCartProvider, CheckoutProvider } from 'context/checkout'

import ToastContainer from 'components/layout/ToastNotification'
import App from 'components/App'
import ProtectedRoute from 'components/auth/ProtectedRoute'
import AuthCallbackPage from 'components/pages/AuthCallbackPage'
import ShopLayout from 'components/shop/ShopLayout'
import ProductListPage from 'components/pages/ProductListPage'
import ProductItemPage from 'components/pages/ProductItemPage'
import OrderListPage from 'components/orders/OrderListPage'
import OrderItemPage from 'components/orders/OrderItemPage'
import CheckoutLayout from 'components/checkout/CheckoutLayout'
import CartContentPage from 'components/pages/CartContentPage'
import DeliveryAddressPage from 'components/pages/DeliveryAddressPage'
import CheckoutSummaryPage from 'components/pages/CheckoutSummaryPage'
import CheckoutConfirmationPage from 'components/pages/CheckoutConfirmationPage'
import VWCartContentPage from 'components/pages/VWCartContentPage'
import DestinationPage from 'components/pages/DestinationPage'
import DeliveryMethodPage from 'components/pages/DeliveryMethodPage'
import MidnightMadnessPage from 'components/pages/MidnightMadnessPage'
import SignOutCallbackPage from 'components/pages/SignOutCallbackPage'
import NotFoundPage from 'components/pages/NotFoundPage'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <>
    <BrowserRouter>
      <ToastContainer />

      <AuthContextProvider>
        <ReactQueryContextProvider>
          <ThemeProvider>
            <PrivacyProvider>
              <TelemetryProvider>
                <Routes>
                  <Route path='authcallback' element={<AuthCallbackPage />} />
                  <Route path='signoutcallback' element={<SignOutCallbackPage />} />
                  <Route path='404' element={<NotFoundPage />} />

                  <Route path=':localeCode' element={<App />}>
                    <Route path='shop'>
                      <Route
                        path=':shopId'
                        element={
                          <ShopProvider>
                            <CartProvider>
                              <ShopLayout />
                            </CartProvider>
                          </ShopProvider>
                        }
                      >
                        <Route path='products'>
                          <Route index element={<ProductListPage />} />
                          <Route path=':productId' element={<ProductItemPage />} />
                        </Route>

                        <Route path='orders'>
                          <Route
                            index
                            element={
                              <ProtectedRoute>
                                <OrderListPage />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path=':orderId'
                            element={
                              <ProtectedRoute>
                                <OrderItemPage />
                              </ProtectedRoute>
                            }
                          />
                        </Route>

                        <Route index element={<Navigate to='products' replace={true} />} />
                      </Route>

                      <Route index element={<Navigate to='..' replace={true} />} />
                    </Route>

                    <Route path='cart'>
                      <Route
                        path=':cartId'
                        element={
                          <ProtectedRoute>
                            <CheckoutProvider>
                              <CheckoutLayout />
                            </CheckoutProvider>
                          </ProtectedRoute>
                        }
                      >
                        <Route index element={<CartContentPage />} />
                        <Route path='destination' element={<DestinationPage />} />
                        <Route path='delivery' element={<DeliveryMethodPage />} />
                        <Route path='address' element={<DeliveryAddressPage />} />
                        <Route path='mm' element={<MidnightMadnessPage />} />
                        <Route path='summary' element={<CheckoutSummaryPage />} />
                        <Route path='confirmation' element={<CheckoutConfirmationPage />} />
                      </Route>

                      <Route index element={<Navigate to='..' replace={true} />} />
                    </Route>

                    <Route path='vcart'>
                      <Route path=':vcartId'>
                        <Route
                          index
                          element={
                            <VWCartProvider>
                              <VWCartContentPage />
                            </VWCartProvider>
                          }
                        />

                        <Route path='*' element={<Navigate to='..' replace={true} />} />
                      </Route>

                      <Route index element={<Navigate to='..' replace={true} />} />
                    </Route>

                    <Route path='*' element={<Navigate to='..' replace={true} />} />
                  </Route>

                  <Route path='*' element={<Navigate to='404' replace={true} />} />
                </Routes>
              </TelemetryProvider>
            </PrivacyProvider>
          </ThemeProvider>
        </ReactQueryContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </>
)

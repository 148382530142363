import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'framer-motion'

import { AiOutlineMail } from 'react-icons/ai'
import { BsFillPencilFill } from 'react-icons/bs'
import { FiPhone } from 'react-icons/fi'
import { ImSpinner2 } from 'react-icons/im'
import { MdOutlineSearch } from 'react-icons/md'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'
import { RxMobile } from 'react-icons/rx'
import { TfiLocationPin } from 'react-icons/tfi'

import type { IGenericServerError } from 'typings/errorTypes'
import type {
  DeliveryMethodError,
  IBasketWithDeliveryAndItemDetails,
  IDeliveryAddress,
  IDeliveryMethodErrorResponse
} from 'typings/checkoutApi'

import { getCountryName } from 'utils/locationUtils'
import { submitDeliveryMethod } from 'services/checkout'
import { useCheckoutContext } from 'context/checkout'
import { useAuthContext } from 'context/auth'
import { useTelemetryContext } from 'context/insights'
import { useAppContext } from 'context/app'

import { containerVariants, contentVariants } from './DeliveryAddressPage.motion'
import { showToast } from 'components/layout/ToastNotification'
import PersistentPortal from 'components/common/PersistentPortal'
import Button from 'components/common/buttons/Button'
import DeliveryMethodCard from 'components/delivery/DeliveryMethodCard'
import VWInfo from 'components/vw/VWInfo'
import MessageBox from 'components/common/MessageBox'
import TranslatedError from 'components/common/TranslatedError'
import { ShippingAddressForm } from '@pmi.web/react-user-forms'
import { parseCountryCode } from '@pmi.web/countries'

const DeliveryAddressPage: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    cartId: cartIdPathParam,
    localeCode: localeCodePathParam,
    vcartId: vcartIdPathParam
  } = useParams()
  const queryClient = useQueryClient()
  const { accessToken, isCustomer, partnerId } = useAuthContext()
  const { t: translated } = useTranslation()
  const { appInsights } = useTelemetryContext()
  const { shopId } = useAppContext()
  const {
    defaultAddress,
    pickupDeliveryMethods,
    nonPickupDeliveryMethods,
    isPickupSelected,
    idOfSelectedShippingMethod,
    setIdOfSelectedShippingMethod,
    goesToVirtualWarehouse,
    isPaid,
    isCanceled,
    checkoutCartContentQueryKey
  } = useCheckoutContext()
  const searchInputRef = useRef<HTMLInputElement>(null)
  const messageBoxRef = useRef<HTMLDivElement>(null)

  const [deliveryMethodErrors, setDeliveryMethodErrors] = useState<DeliveryMethodError[]>([])
  const [hasCustomAddress, setHasCustomAddress] = useState(false)
  const [pickupSearchInput, setPickupSearchInput] = useState('')

  const cartId = cartIdPathParam || vcartIdPathParam || ''

  const filteredPickupDeliveryMethods = useMemo(
    () =>
      pickupDeliveryMethods.filter(method =>
        method?.localizedLabels?.[0]?.name
          ?.toLowerCase()
          ?.includes(pickupSearchInput?.toLocaleLowerCase())
      ),
    [pickupDeliveryMethods, pickupSearchInput]
  )

  const submitDeliveryMethodMutation = useMutation<
    IBasketWithDeliveryAndItemDetails,
    IDeliveryMethodErrorResponse | IGenericServerError,
    IDeliveryAddress
  >({
    mutationFn: deliveryAddress =>
      submitDeliveryMethod(
        cartId,
        accessToken ?? '',
        {
          goesToVirtualWarehouse,
          selectedDeliveryMethodId: idOfSelectedShippingMethod ?? '',
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          shipTo: deliveryAddress
        },
        localeCodePathParam ?? ''
      ),
    onSuccess: responseData => {
      appInsights?.trackEvent({
        name: 'CheckoutDeliverySubmission',
        properties: {
          isTeamPartner: !isCustomer,
          customerId: partnerId,
          shopId,
          cartId,
          deliveryMethod: idOfSelectedShippingMethod,
          hasCustomAddress,
          status: 'success'
        }
      })
      queryClient.setQueryData(checkoutCartContentQueryKey, responseData)
      navigate(
        { pathname: '../mm', search: location.search },
        { state: { source: 'checkout-address' } }
      )
    },
    onError: deliveryMethodValidationError => {
      appInsights?.trackEvent({
        name: 'CheckoutDeliverySubmission',
        properties: {
          isTeamPartner: !isCustomer,
          customerId: partnerId,
          shopId,
          cartId,
          deliveryMethod: idOfSelectedShippingMethod,
          hasCustomAddress,
          status: 'error',
          error: deliveryMethodValidationError
        }
      })
      if ('globalValidationResults' in deliveryMethodValidationError) {
        setDeliveryMethodErrors(deliveryMethodValidationError.globalValidationResults)
      } else {
        showToast({
          type: 'error',
          title: translated('Failed to submit delivery method.'),
          message: JSON.stringify(deliveryMethodValidationError)
        })
      }
    }
  })

  useEffect(() => {
    if (deliveryMethodErrors?.length > 0)
      messageBoxRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
  }, [deliveryMethodErrors])

  const isSelectedDeliveryMethodShippingType =
    nonPickupDeliveryMethods?.find(method => method.id === idOfSelectedShippingMethod)?.type ===
    'shipment'

  const handleDeliveryMethodSubmission = useCallback(
    (deliveryAddress: IDeliveryAddress | undefined) => {
      if (!deliveryAddress) return setDeliveryMethodErrors(['missing_delivery_address'])
      if (!submitDeliveryMethodMutation.isLoading) {
        submitDeliveryMethodMutation.mutate(deliveryAddress)
      }
    },
    [submitDeliveryMethodMutation]
  )

  const deliveryCountry = useMemo(() => {
    try {
      return parseCountryCode(defaultAddress?.address?.country ?? '')
    } catch (error) {
      // TODO: add telemetry to log error
      return undefined
    }
  }, [defaultAddress?.address?.country])

  return !isSelectedDeliveryMethodShippingType && !isPickupSelected ? (
    <Navigate
      to={{
        pathname: '../delivery',
        search: location.search
      }}
      replace={true}
    />
  ) : (
    <motion.div
      className={
        'w-full max-w-3xl mx-auto sm:p-4' +
        ' flex-1 overflow-hidden' +
        ' flex flex-col' +
        ' bg-white' +
        ' border border-secondary rounded-t-md' +
        ' shadow-md'
      }
      variants={containerVariants}
    >
      <VWInfo isVisible={goesToVirtualWarehouse} />

      {/* --------------- error box: start --------------- */}
      {deliveryMethodErrors?.map(error => (
        <MessageBox
          key={error}
          type='error'
          text={<TranslatedError error={error} />}
          className='my-4'
          showIcon={true}
          // ref={messageBoxRef}
        />
      ))}
      {/* --------------- error box: end --------------- */}

      {/* --------------- main content: start --------------- */}
      <motion.div className={'flex-1 container p-4'} variants={contentVariants}>
        <div className='w-full space-y-4 mb-4 md:mb-6'>
          <h3 className='text-center text-base font-bold leading-6 text-gray-700'>
            {isPickupSelected ? translated('Pick-up location') : translated('Shipping address')}
          </h3>

          {isPickupSelected && (
            <label
              className={
                'h-10 w-96 max-w-full mx-auto px-3' +
                ' flex justify-between items-center gap-2' +
                ' rounded-full border-2 border-secondary-darker bg-white'
              }
              htmlFor='pickup-location-search-input'
            >
              <MdOutlineSearch size={24} className='text-primary animate-pulse' />

              <input
                type='text'
                className={
                  'w-full' +
                  ' bg-transparent' +
                  ' placeholder:text-gray-400' +
                  ' focus:outline-none focus:ring-transparent focus-visible:ring'
                }
                placeholder={translated('Search pick-up location')}
                ref={searchInputRef}
                value={pickupSearchInput}
                onChange={event => {
                  setPickupSearchInput(event.target.value)
                  if (idOfSelectedShippingMethod) setIdOfSelectedShippingMethod(undefined)
                }}
                name='pickup-location-search-input'
                id='pickup-location-search-input'
              />
            </label>
          )}

          {/* --------------- pick-up method options: start --------------- */}
          {isPickupSelected && (
            <form className={'w-full mx-auto space-y-4'}>
              {filteredPickupDeliveryMethods.map(deliveryMethod => (
                <DeliveryMethodCard
                  key={deliveryMethod.id}
                  id={deliveryMethod.id}
                  name={deliveryMethod.localizedLabels[0].name}
                  details={deliveryMethod.localizedLabels[0].info}
                  price={deliveryMethod.cost.displayPrice}
                  currencyCode={deliveryMethod.cost.currencyCode}
                  isSelected={idOfSelectedShippingMethod === deliveryMethod.id}
                  handleSelection={() => setIdOfSelectedShippingMethod(deliveryMethod.id)}
                  customClasses='mx-auto'
                />
              ))}
            </form>
          )}
          {/* --------------- pick-up method options: end --------------- */}

          {/* --------------- address panels: start --------------- */}
          {isSelectedDeliveryMethodShippingType && (
            <div className='relative w-full overflow-hidden'>
              <AnimatePresence mode='popLayout'>
                {isSelectedDeliveryMethodShippingType && hasCustomAddress && deliveryCountry ? (
                  // --------------- shipping address panel: start ---------------
                  <motion.div
                    className='w-full space-y-2 md:space-y-4'
                    key='shipping-address-form'
                    initial={{ x: '100%' }}
                    animate={{ x: '0%' }}
                    exit={{ x: '100%' }}
                    transition={{
                      tension: 190,
                      friction: 70,
                      mass: 0.4
                    }}
                  >
                    <button
                      className='ml-2 sm:ml-0 flex items-center text-primary'
                      onClick={() => setHasCustomAddress(false)}
                    >
                      <RiArrowLeftSLine size={24} className='inline-block' />
                      <span className=''>{translated('Use default address')}</span>
                    </button>

                    <ShippingAddressForm
                      country={deliveryCountry}
                      language={localeCodePathParam ?? ''}
                      onSubmit={shippingAddress =>
                        handleDeliveryMethodSubmission(
                          shippingAddress as unknown as IDeliveryAddress
                        )
                      }
                      formId='shipping-address-form'
                      defaultValues={defaultAddress}
                    />
                  </motion.div>
                ) : (
                  // --------------- shipping address panel: end ---------------

                  // --------------- default address panel: start ---------------
                  <motion.div
                    className='w-full space-y-2 md:space-y-4'
                    initial={{ x: '-100%' }}
                    animate={{ x: '0%' }}
                    exit={{ x: '-100%' }}
                    transition={{
                      tension: 190,
                      friction: 70,
                      mass: 0.4
                    }}
                    key='billing-address-form'
                  >
                    <div
                      className={
                        'w-96 max-w-full mx-auto space-y-1 py-4 px-6' +
                        ' rounded-md border-2' +
                        ' border-secondary' +
                        ' text-sm'
                      }
                    >
                      <div className='grid grid-cols-[1.25rem_1fr] gap-2 items-start'>
                        <div className='justify-self-start'>
                          <TfiLocationPin size={18} className='inline-block' />
                        </div>

                        <div className='inline-block'>
                          {defaultAddress?.fullName && <p>{defaultAddress.fullName}</p>}
                          {defaultAddress?.address?.addressLine1 && (
                            <p>{defaultAddress.address.addressLine1}</p>
                          )}
                          {defaultAddress?.address?.addressLine2 && (
                            <p>{defaultAddress.address.addressLine2}</p>
                          )}
                          <p>
                            {defaultAddress?.address?.locality && (
                              <span>{defaultAddress.address.locality},&nbsp;</span>
                            )}
                            {defaultAddress?.address?.zip && (
                              <span>{defaultAddress.address.zip}</span>
                            )}
                          </p>
                          {defaultAddress?.address?.country && (
                            <p>
                              {getCountryName(
                                defaultAddress.address.country,
                                localeCodePathParam ?? ''
                              )}
                            </p>
                          )}
                          {defaultAddress?.address?.administrativeDistrictLevel1 && (
                            <p>{defaultAddress.address.administrativeDistrictLevel1}</p>
                          )}
                          {defaultAddress?.address?.administrativeDistrictLevel2 && (
                            <p>{defaultAddress.address.administrativeDistrictLevel2}</p>
                          )}
                          {defaultAddress?.address?.administrativeDistrictLevel3 && (
                            <p>{defaultAddress.address.administrativeDistrictLevel3}</p>
                          )}
                        </div>
                      </div>

                      {defaultAddress?.email && (
                        <div className='grid grid-cols-[1.25rem_1fr] gap-2 items-start'>
                          <div className='justify-self-start'>
                            <AiOutlineMail size={17} className='inline-block' />
                          </div>

                          <div className='inline-block'>
                            <p>{defaultAddress.email}</p>
                          </div>
                        </div>
                      )}

                      {defaultAddress?.mobile && (
                        <div className='grid grid-cols-[1.25rem_1fr] gap-2 items-start'>
                          <div className='justify-self-start'>
                            <RxMobile size={20} className='-ml-0.5' />
                          </div>

                          <div className='inline-block'>
                            <p>{defaultAddress.mobile}</p>
                          </div>
                        </div>
                      )}

                      {defaultAddress?.phone && (
                        <div className='grid grid-cols-[1.25rem_1fr] gap-2 items-start'>
                          <div className='justify-self-start'>
                            <FiPhone size={17} className='inline-block' />
                          </div>

                          <div className='inline-block'>
                            <p>{defaultAddress.phone}</p>
                          </div>
                        </div>
                      )}
                    </div>

                    <AnimatePresence>
                      {isSelectedDeliveryMethodShippingType && deliveryCountry && (
                        <motion.button
                          className='mx-auto flex justify-center items-center gap-2 text-primary'
                          onClick={() => setHasCustomAddress(true)}
                          initial={{ opacity: 0, x: '50%' }}
                          animate={{ opacity: 1, x: '0%' }}
                          exit={{ opacity: 0, x: '50%' }}
                          transition={{ duration: 0.3 }}
                        >
                          <BsFillPencilFill size={16} className='inline-block' />
                          <span className=''>{translated('Ship to a different address')}</span>
                        </motion.button>
                      )}
                    </AnimatePresence>
                  </motion.div>
                  // --------------- default address panel: end ---------------
                )}
              </AnimatePresence>
            </div>
          )}
          {/* --------------- address panels: end --------------- */}
        </div>
      </motion.div>
      {/* --------------- main content: end --------------- */}

      {/* --------------- footer: start --------------- */}
      <PersistentPortal containerElementSelector='#footer-buttons'>
        {/* --------------- buttons: start --------------- */}
        <div className='flex flex-row justify-between items-center gap-4'>
          <button
            className='flex items-center text-primary'
            onClick={() =>
              navigate(
                { pathname: '../delivery', search: location.search },
                { state: { source: 'checkout-address' } }
              )
            }
          >
            <RiArrowLeftSLine size={24} className='inline-block' />
            <span>{translated('Back')}</span>
          </button>

          <Button
            type={hasCustomAddress ? 'submit' : 'button'}
            form={hasCustomAddress ? 'shipping-address-form' : undefined}
            onClick={
              hasCustomAddress
                ? undefined
                : () => void handleDeliveryMethodSubmission(defaultAddress)
            }
            disabled={submitDeliveryMethodMutation.isLoading || isPaid || isCanceled}
            contentClassName='flex items-center'
            variant='primary'
          >
            {submitDeliveryMethodMutation.isLoading ? (
              <ImSpinner2 size={24} className='animate-spin' />
            ) : (
              <>
                <span>{translated('Confirm')}</span>
                <RiArrowRightSLine size={24} className='-mr-2' />
              </>
            )}
          </Button>
        </div>
        {/* --------------- buttons: end --------------- */}
      </PersistentPortal>
      {/* --------------- footer: end --------------- */}
    </motion.div>
  )
}

export default DeliveryAddressPage

import { FormEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiCheck } from 'react-icons/hi2'
import { ImSpinner2 } from 'react-icons/im'
import { useAppContext } from 'context/app'
import { useCheckoutContext } from 'context/checkout'
import Collapse from 'components/animations/Collapse'
import TextInput from 'components/common/TextInput'
import ChevronDownSemiboldIcon from 'components/common/icons/ChevronDownSemiboldIcon'
import CheckSoidIcon from 'components/common/icons/CheckSolidIcon'

const CheckoutPromoCode = () => {
  const { t: translated } = useTranslation()
  const { isPromoCodeEnabled, userPromoCode } = useAppContext()
  const {
    basketPromoCode,
    isBasketPromoCodeError,
    isBasketPromoCodeLoading,
    promoCodeErrorMessage,
    sponsorNamePromoCode,
    setBasketPromoCode
  } = useCheckoutContext()

  const [promoCodeInput, setPromoCodeInput] = useState(userPromoCode ?? '')
  const [isPromoCodeInputVisible, setIsPromoCodeInputVisible] = useState(!!userPromoCode)
  const [promoCodeInputErrors, setPromoCodeInputErrors] = useState(
    isBasketPromoCodeError ? [promoCodeErrorMessage] : []
  )

  const isSubmitButtonDisabled = !promoCodeInput || isBasketPromoCodeLoading

  useEffect(() => {
    if (!isBasketPromoCodeError) return
    setPromoCodeInputErrors([promoCodeErrorMessage])
  }, [isBasketPromoCodeError, promoCodeErrorMessage])

  // if promo code added by user or in URL, try to apply it automatically
  useEffect(() => {
    if (userPromoCode && !basketPromoCode && !isBasketPromoCodeError && !isBasketPromoCodeLoading) {
      setBasketPromoCode(userPromoCode)
    }
  }, [
    basketPromoCode,
    isBasketPromoCodeError,
    isBasketPromoCodeLoading,
    setBasketPromoCode,
    sponsorNamePromoCode,
    userPromoCode
  ])

  const handlePromoCodeInputChange = useCallback((promoCodeInput: string) => {
    setPromoCodeInput(promoCodeInput?.trim())
    setPromoCodeInputErrors([])
  }, [])

  const handleFormSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (!isPromoCodeEnabled || !promoCodeInput) return
      setBasketPromoCode(promoCodeInput)
    },
    [isPromoCodeEnabled, promoCodeInput, setBasketPromoCode]
  )

  if (!isPromoCodeEnabled) return null

  return basketPromoCode ? (
    <div className='flex items-center gap-2'>
      <div className='grid place-content-center'>
        <CheckSoidIcon
          className={'h-4 w-4 md:h-5 md:w-5 flex-none fill-current self-start text-green-400'}
        />
      </div>
      <p className='grow-1'>
        {sponsorNamePromoCode
          ? translated(
              'Success! Promo code {{promoCode}} from your sponsor {{sponsorName}} is now active.',
              {
                promoCode: basketPromoCode,
                sponsorName: sponsorNamePromoCode
              }
            )
          : translated('Promo code {{promoCode}} applied', {
              promoCode: basketPromoCode
            })}
      </p>
    </div>
  ) : (
    <div className='space-y-1'>
      <button
        className='w-full grid grid-cols-[auto_min-content]'
        onClick={() => setIsPromoCodeInputVisible(prevValue => !prevValue)}
      >
        <p className='text-left'>{translated('Apply a promo code')}</p>

        <div className={'h-6 w-6 grid place-content-center' + ' rounded-full hover:bg-primary/20'}>
          <ChevronDownSemiboldIcon
            className={`w-[10px] transition-all duration-300 text-primary ${
              isPromoCodeInputVisible ? 'rotate-180' : 'rotate-0'
            }`}
          />
        </div>
      </button>

      <Collapse isExpanded={isPromoCodeInputVisible && !basketPromoCode} duration={0.5}>
        <form className='relative' onSubmit={handleFormSubmit}>
          <TextInput<string>
            id='promo-code-input'
            placeholder={translated('Enter a promo code')}
            value={promoCodeInput}
            errors={promoCodeInputErrors}
            changeHandler={handlePromoCodeInputChange}
            customWrapperClasses='w-full'
            customInputClasses='pr-16'
          />

          <button
            type='submit'
            className={
              'absolute top-1.5 right-2' +
              ' py-2 px-4 rounded-full' +
              ' text-primary text-sm font-bold hover:text-white disabled:hover:text-primary' +
              ' bg-transparent hover:bg-primary disabled:hover:bg-transparent' +
              ' flex items-center gap-1' +
              ' disabled:cursor-not-allowed disabled:opacity-50 cursor-pointer opacity-100'
            }
            disabled={isSubmitButtonDisabled}
          >
            <span className='-ml-1 flex items-center text-white'>
              {isBasketPromoCodeLoading ? (
                <ImSpinner2 className='h-3 w-3 animate-spin' />
              ) : basketPromoCode ? (
                <HiCheck className='h-3 w-3' aria-hidden='true' />
              ) : null}
            </span>
            {isBasketPromoCodeLoading ? (
              <span className='inline-block'>{translated('Checking')}</span>
            ) : (
              <span className='inline-block'>{translated('Apply')}</span>
            )}
          </button>
        </form>
      </Collapse>
    </div>
  )
}

export default CheckoutPromoCode

import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'

import { Menu, Transition } from '@headlessui/react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { ImSpinner2 } from 'react-icons/im'

import type { CustomErrorMessage } from 'typings/errorTypes'
import type {
  GlobalCartValidationError,
  ICartContentValidationError,
  IGlobalCartValidationError
} from 'typings/checkoutApi'

import { formatPrice } from 'utils/priceUtils'
import { measurementUnitMap } from 'utils/unitUtils'
import { validateBasketContent } from 'services/checkout'
import useModal from 'hooks/useModal'
import { useAuthContext } from 'context/auth'
import { useTelemetryContext } from 'context/insights'
import { useAppContext } from 'context/app'
import { useCartContext } from 'context/cart'

import TrashIcon from 'components/common/icons/TrashIcon'
import CloudDownloadIcon from 'components/common/icons/CloudDownloadIcon'
import Drawer from 'components/common/Drawer'
import SpinnerPM from 'components/common/SpinnerPM'
import MessageBox from 'components/common/MessageBox'
import BasicButton from 'components/common/buttons/BasicButton'
import CartItem from 'components/checkout/CartItem'
import AnimatedNumber from 'components/effects/AnimatedNumber'
import PMPoints from 'components/common/PMPoints'
import Modal from 'components/common/Modal'
import Button from 'components/common/buttons/Button'
import TranslatedError from 'components/common/TranslatedError'

type CartDrawerProps = {
  isOpen: boolean
  setIsOpen: (state: boolean) => void
}

const CartDrawer: FC<CartDrawerProps> = ({ isOpen, setIsOpen }) => {
  const queryClient = useQueryClient()
  const { localeCode: localeCodePathParam } = useParams()
  const navigate = useNavigate()
  const { t: translated } = useTranslation()
  const { accessToken, isCustomer, partnerId } = useAuthContext()
  const { appInsights } = useTelemetryContext()
  const { cartId, shopId } = useAppContext()
  const {
    cartItems,
    emptyCart,
    isCartContentLoading,
    isCartUploading,
    cartContentError,
    addUnitsToCart,
    removeUnitsFromCart,
    setCartArticleUnits,
    itemValidationErrorMap,
    setItemValidationErrorMap,
    cartContentQueryKey,
    cartSubtotal
  } = useCartContext()

  const { isModalOpen, openModal, closeModal } = useModal()

  const messageBoxRef = useRef<HTMLDivElement>(null)

  const [cartValidationErrors, setCartValidationErrors] = useState<GlobalCartValidationError[]>([])

  const validateCartContentMutation = useMutation<
    boolean,
    ICartContentValidationError | IGlobalCartValidationError
  >({
    mutationFn: () => validateBasketContent(cartId ?? '', accessToken ?? ''),
    onSuccess: () => {
      appInsights?.trackEvent({
        name: 'ShopBasketValidation',
        properties: {
          isTeamPartner: !isCustomer,
          customerId: partnerId,
          shopId,
          cartId,
          status: 'success'
        }
      })
      queryClient.invalidateQueries(cartContentQueryKey)
      navigate(
        { pathname: `/${localeCodePathParam}/cart/${cartId}/destination`, search: location.search },
        { state: { source: 'cart-drawer' } }
      )
    },
    onError: cartValidationError => {
      appInsights?.trackEvent({
        name: 'ShopBasketValidation',
        properties: {
          isTeamPartner: !isCustomer,
          customerId: partnerId,
          shopId,
          cartId,
          status: 'error',
          error: cartValidationError
        }
      })

      if ('itemValidationResults' in cartValidationError) {
        const itemErrorMap = new Map<string, CustomErrorMessage>()
        cartValidationError.itemValidationResults.forEach(itemValidationError => {
          if (itemValidationError.result === 'ok') return
          itemErrorMap.set(itemValidationError.articleNumber, itemValidationError.result)
        })
        setItemValidationErrorMap(itemErrorMap)
      } else if ('globalValidationResults' in cartValidationError) {
        setCartValidationErrors(cartValidationError.globalValidationResults)
      } else {
        setCartValidationErrors(['basket_validation_failure'])
      }
    }
  })

  useEffect(() => {
    if (cartValidationErrors?.length > 0)
      messageBoxRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
  }, [cartValidationErrors])

  const handleIncrement = useCallback(
    (articleNumber: string) => {
      if (!articleNumber || !cartId || isCartUploading) return
      addUnitsToCart(articleNumber, 1)
      setCartValidationErrors([])
    },
    [addUnitsToCart, cartId, isCartUploading]
  )

  const handleDecrement = useCallback(
    (articleNumber: string) => {
      if (!articleNumber || !cartId || isCartUploading) return
      removeUnitsFromCart(articleNumber, 1)
      setCartValidationErrors([])
    },
    [cartId, isCartUploading, removeUnitsFromCart]
  )

  const handleSetValue = useCallback(
    (articleNumber: string, value: number) => {
      if (!articleNumber || !cartId || isCartUploading) return
      setCartArticleUnits(articleNumber, value)
      setCartValidationErrors([])
    },
    [cartId, isCartUploading, setCartArticleUnits]
  )

  const handleCartSubmission = useCallback(() => {
    validateCartContentMutation.mutate()
  }, [validateCartContentMutation])

  const handleEmptyCart = useCallback(() => {
    emptyCart()
    closeModal()
  }, [closeModal, emptyCart])

  const cartCurrency = cartItems[0]?.currency
  const cartPoints = cartItems?.reduce(
    (output, { quantity, points }) => output + points * quantity,
    0
  )

  return (
    <>
      <Drawer isOpen={isOpen && !!cartItems.length} setIsOpen={setIsOpen}>
        <Menu as='div' className='z-10 absolute top-2 md:top-2 right-2 md:right-2'>
          <Menu.Button
            className={
              'h-6 w-6 md:h-8 md:w-8 rounded-full' +
              ' grid place-content-center' +
              ' bg-transparent hover:bg-primary-lightest' +
              ' text-main hover:text-primary'
            }
          >
            <BiDotsVerticalRounded className='text-lg md:text-xl' />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              className={
                'z-10 absolute right-0 mt-2 w-56 origin-top-right' +
                ' divide-y divide-gray-100' +
                ' rounded-md border-2 border-gray-100 bg-white shadow-lg'
              }
            >
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={openModal}
                    className={
                      'group flex w-full items-center rounded-md m-1 px-2 py-2 text-sm' +
                      (active ? ' bg-secondary' : ' bg-transparent')
                    }
                  >
                    <TrashIcon className='h-5 w-5 -m-0.5 mr-1.5 text-primary' />
                    {translated('Empty the cart')}
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>

        <div
          className={
            'z-0 relative h-full w-full overflow-hidden' +
            ' grid grid-cols-1 grid-rows-[min-content_auto_min-content]'
          }
        >
          <h2
            id='drawer-title'
            className={
              'pt-2 md:pt-3 pb-1 sm:pb-2' +
              ' text-lg text-center tracking-wide' +
              ' bg-secondary-lighter'
            }
          >
            {translated('Shopping cart')}
          </h2>

          {isCartContentLoading ? (
            <div className='grid place-content-center'>
              <SpinnerPM>
                <CloudDownloadIcon />
              </SpinnerPM>
            </div>
          ) : cartContentError?.length ? (
            <div className='grid place-content-center'>
              <MessageBox type='error' text={cartContentError} showIcon={true} />
            </div>
          ) : cartItems?.length ? (
            <div className='overflow-y-scroll'>
              {/* --------------- message box: start --------------- */}
              {cartValidationErrors?.map(error => (
                <MessageBox
                  key={error}
                  type='error'
                  text={<TranslatedError error={error} />}
                  className='my-4'
                  showIcon={true}
                  ref={messageBoxRef}
                />
              ))}
              {/* --------------- message box: end --------------- */}
              <ul
                role='list'
                className={
                  'px-2' +
                  ' overflow-y-auto overflow-x-hidden overscroll-contain' +
                  ' divide-y divide-secondary-darker'
                }
              >
                <AnimatePresence>
                  {cartItems.map((cartItem, index) => (
                    <motion.li
                      key={cartItem.articleNumber}
                      layout
                      transition={{ duration: 0.3, delay: index * 0.1 }}
                      exit={{
                        opacity: 0,
                        x: '50%',
                        transition: { duration: 0.1, delay: 0 }
                      }}
                    >
                      <CartItem
                        {...cartItem}
                        isDisabled={validateCartContentMutation.isLoading}
                        inStock={cartItem.inStock}
                        maxAmount={cartItem.maxAmount}
                        incrementValue={() => handleIncrement(cartItem.articleNumber)}
                        decrementValue={() => handleDecrement(cartItem.articleNumber)}
                        setValue={value => handleSetValue(cartItem.articleNumber, value)}
                        validationError={itemValidationErrorMap?.get(cartItem.articleNumber)}
                        clearError={() =>
                          setItemValidationErrorMap(prevMap => {
                            const newErrorMap = new Map(prevMap)
                            newErrorMap?.delete(cartItem.articleNumber)
                            return newErrorMap
                          })
                        }
                        isBusy={isCartUploading}
                        amountInUnits={cartItem?.amountInUnits}
                        unitSymbol={measurementUnitMap.get(cartItem?.unit ?? '')}
                      />
                    </motion.li>
                  ))}
                </AnimatePresence>
              </ul>
            </div>
          ) : (
            <div className='grid items-center'>
              <div className='h-24 w-full mt-4 grid place-content-center rounded-md border border-gray-200'>
                <p className='text-gray-500'>{translated('Your shopping cart is empty')}</p>
              </div>
            </div>
          )}

          <div className={'overflow-hidden py-2 sm:py-3 px-3' + ' bg-secondary'}>
            <p className='flex justify-between items-center'>
              <span>{translated('Subtotal')}</span>
              {cartSubtotal && cartCurrency && localeCodePathParam ? (
                <AnimatedNumber
                  value={formatPrice(cartSubtotal, cartCurrency, localeCodePathParam ?? '')}
                  className='font-bold'
                />
              ) : (
                <span className='font-bold'>0</span>
              )}
            </p>

            {isCustomer ? null : (
              <p className='flex justify-between items-center'>
                <span>{translated('Points')}</span>
                <PMPoints value={cartPoints} animated={true} />
              </p>
            )}

            <div className='mt-4 sm:mt-6'>
              <BasicButton
                className='block w-full py-2 px-4 bg-primary text-white'
                onClick={handleCartSubmission}
                disabled={
                  isCartContentLoading ||
                  !!cartContentError?.length ||
                  !cartItems?.length ||
                  !!itemValidationErrorMap?.size ||
                  validateCartContentMutation.isLoading
                }
              >
                {validateCartContentMutation.isLoading ? (
                  <ImSpinner2 size={24} className='animate-spin mx-auto' />
                ) : (
                  <span>{translated('Confirm')}</span>
                )}
              </BasicButton>
            </div>
          </div>
        </div>
      </Drawer>

      <Modal isOpen={isModalOpen} onClose={closeModal} size='md'>
        <h1 className='text-base text-center'>
          {translated('Are you sure you would like to empty the cart?')}
        </h1>

        <div className='flex items-center justify-around'>
          <Button containerClassName='mt-4' variant='outlined' onClick={handleEmptyCart}>
            {translated('Confirm')}
          </Button>

          <Button containerClassName='mt-4' variant='outlined' onClick={closeModal}>
            {translated('Cancel')}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default CartDrawer

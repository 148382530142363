import { FC } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'

import { useCheckoutContext } from 'context/checkout'

import { containerVariants, contentVariants } from './DeliveryMethodPage.motion'
import PersistentPortal from 'components/common/PersistentPortal'
import Button from 'components/common/buttons/Button'
import DeliveryMethodCard from 'components/delivery/DeliveryMethodCard'
import Collapse from 'components/animations/Collapse'
import VWInfo from 'components/vw/VWInfo'

const DeliveryMethodPage: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { vcartId: vcartIdPathParam } = useParams()
  const { t: translated } = useTranslation()
  const {
    pickupDeliveryMethods,
    nonPickupDeliveryMethods,
    isPickupSelected,
    setIsPickupSelected,
    idOfSelectedShippingMethod,
    setIdOfSelectedShippingMethod,
    goesToVirtualWarehouse,
    isPaid,
    isCanceled
  } = useCheckoutContext()

  const hasDeliveryMethodBeenSelected = isPickupSelected || !!idOfSelectedShippingMethod

  return (
    <motion.div
      className={
        'w-full max-w-3xl mx-auto sm:p-4' +
        ' flex-1 overflow-hidden' +
        ' flex flex-col' +
        ' bg-white' +
        ' border border-secondary rounded-t-md' +
        ' shadow-md'
      }
      variants={containerVariants}
    >
      <VWInfo isVisible={goesToVirtualWarehouse} />

      {/* --------------- main content: start --------------- */}
      <motion.div className={'relative flex-1 container p-4'} variants={contentVariants}>
        <h3 className='text-center text-base font-bold leading-6 text-gray-700'>
          {translated('Delivery method')}
        </h3>

        {pickupDeliveryMethods?.length + nonPickupDeliveryMethods?.length ? (
          <div
            className={
              'w-full mt-2 md:mt-4' +
              ' flex flex-col justify-center items-center' +
              (idOfSelectedShippingMethod || isPickupSelected ? '' : ' gap-y-2 md:gap-y-4')
            }
          >
            <Collapse isExpanded={!idOfSelectedShippingMethod && !isPickupSelected}>
              <p className='text-sm text-rose-500'>{translated('Choose a shipping method')}</p>
            </Collapse>

            <form
              id='delivery-method'
              className={'w-full flex flex-col justify-center items-center gap-2 md:gap-4'}
            >
              {pickupDeliveryMethods?.length > 0 && (
                <DeliveryMethodCard
                  key='pickup-delivery-method'
                  id='pickup-delivery-method'
                  name={translated('Pick up')}
                  details={translated('I will pick up the products myself')}
                  price={0}
                  currencyCode=''
                  isSelected={isPickupSelected}
                  handleSelection={() => {
                    setIdOfSelectedShippingMethod(undefined)
                    setIsPickupSelected(true)
                  }}
                />
              )}
              {nonPickupDeliveryMethods.map(deliveryMethod => (
                <DeliveryMethodCard
                  key={deliveryMethod.id}
                  id={deliveryMethod.id}
                  name={deliveryMethod.localizedLabels[0].name}
                  details={deliveryMethod.localizedLabels[0].info}
                  price={deliveryMethod.cost.displayPrice}
                  currencyCode={deliveryMethod.cost.currencyCode}
                  isSelected={idOfSelectedShippingMethod === deliveryMethod.id}
                  handleSelection={() => {
                    setIdOfSelectedShippingMethod(deliveryMethod.id)
                    setIsPickupSelected(false)
                  }}
                />
              ))}
            </form>
          </div>
        ) : (
          <div className='grid items-center p-4'>
            <div
              className={
                'h-24 w-full p-3 grid place-content-center' +
                ' rounded-md border border-gray-200 bg-white'
              }
            >
              <p className='text-center text-gray-500'>
                {translated('No delivery methods are available.')}
              </p>
            </div>
          </div>
        )}
      </motion.div>
      {/* --------------- main content: end --------------- */}

      {/* --------------- footer: start --------------- */}
      <PersistentPortal containerElementSelector='#footer-buttons'>
        {/* --------------- buttons: start --------------- */}
        <div className='flex flex-row justify-between items-center gap-4'>
          <button
            className='flex items-center text-primary'
            onClick={() =>
              navigate(
                // the VW check-out flow doesn't have a destination selection step
                { pathname: vcartIdPathParam ? '..' : '../destination', search: location.search },
                { state: { source: 'checkout-delivery' } }
              )
            }
          >
            <RiArrowLeftSLine size={24} className='inline-block' />
            <span>{translated('Back')}</span>
          </button>

          <Button
            onClick={() => {
              navigate(
                { pathname: '../address', search: location.search },
                { state: { source: 'checkout-delivery' } }
              )
            }}
            disabled={!hasDeliveryMethodBeenSelected || isPaid || isCanceled}
            contentClassName='flex items-center'
            variant='primary'
          >
            <span>{translated('Confirm')}</span>
            <RiArrowRightSLine size={24} className='-mr-2' />
          </Button>
        </div>
        {/* --------------- buttons: end --------------- */}
      </PersistentPortal>
      {/* --------------- footer: end --------------- */}
    </motion.div>
  )
}

export default DeliveryMethodPage

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomErrorMessage } from 'typings/errorTypes'

type TEProps = {
  error: CustomErrorMessage
}

const TranslatedError: FC<TEProps> = ({ error }) => {
  const { t: translated } = useTranslation()

  const errorMap: Map<CustomErrorMessage, string> = new Map([
    [
      'invalid_address',
      translated('Your shipping address is invalid. Please correct it in order to proceed.')
    ],
    [
      'wrong_country',
      translated("You can't buy products in this shop. Please try again in your country's shop.")
    ],
    ['empty_basket', translated('The shopping cart is empty.')],
    ['already_paid', translated('This order has already been paid.')],
    ['not_payable', translated('Payment for this shopping cart cannot be processed.')],
    ['missing_delivery_address', translated('The shipping address is missing.')],
    [
      'exceeded_shop_total_points',
      translated(
        'You have exceeded the total number of points you are allowed to purchase in this shop.'
      )
    ],
    ['out_of_stock', translated('This product is out of stock.')],
    [
      'max_order_quantity_exceeded',
      translated('The amount of this product allowed per order exceeded.')
    ],
    [
      'max_customer_quantity_exceeded',
      translated('You have exceeded the amount of this product that you are allowed to purchase.')
    ],
    ['invalid_product', translated('Invalid product.')],
    [
      'cannot_check_out_product',
      translated(
        'This product can no longer be purchased. Please remove it from the basket before proceeding!'
      )
    ],
    ['basket_validation_failure', translated('Shopping cart validation has failed.')],
    [
      'product_not_allowed_in_vw',
      translated('This product is not eligible for the Virtual Warehouse.')
    ],
    [
      'uknown_delivery_method',
      translated(
        'The selected delivery method is no longer available. Please select a different one and try again!'
      )
    ]
  ])

  return <>{errorMap.get(error)}</>
}

export default TranslatedError
